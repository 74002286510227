import { PROFILE_TYPES } from '../profile/types';

import { BILLING_TYPES, State, ActionType } from './types';

const initialState: State = {
  transactions: [],
  webTransactions: [],
  webAllSubscriptions: [],
  webOneTimePurchases: [],
  pending: false,
  productsLoadSuccess: false,
  purchased: false,
  purchaseDate: 0,
  purchasedAstrologers: false,
  products: [],
  isFamilyProductSubscribed: false,
  isFakePurchaseActive: true,
  isWebSubscriptionShowed: false,
  webPaymentMethod: '',
  redemptionSubscription: null,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case BILLING_TYPES.SET_WEB_TRANSACTIONS:
      return {
        ...state,
        webTransactions: action.payload,
      };

    case BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        webAllSubscriptions: action.payload,
      };

    case BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES: {
      return {
        ...state,
        webOneTimePurchases: action.payload,
      };
    }

    case BILLING_TYPES.SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
      };
    }

    case BILLING_TYPES.SET_PURCHASED:
      return {
        ...state,
        purchased: action.payload.purchased,
        purchasedAstrologers: action.payload.purchasedAstrologers,
      };

    case BILLING_TYPES.SET_PURCHASE_DATE:
      return {
        ...state,
        purchaseDate: action.payload,
      };

    case BILLING_TYPES.SET_PRODUCTS_LOAD_SUCCESS:
      return {
        ...state,
        productsLoadSuccess: action.payload,
      };

    case BILLING_TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case BILLING_TYPES.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case BILLING_TYPES.SET_FAMILY_PRODUCT_SUBSCRIBED:
      return {
        ...state,
        isFamilyProductSubscribed: action.payload,
      };

    case BILLING_TYPES.SET_FAKE_PURCHASE_STATUS:
      return {
        ...state,
        isFakePurchaseActive: action.payload,
      };

    case BILLING_TYPES.SET_WEB_SUBSCRIPTION_SHOWED:
      return {
        ...state,
        isWebSubscriptionShowed: true,
      };

    case BILLING_TYPES.SET_WEB_PAYMENT_METHOD:
      return {
        ...state,
        webPaymentMethod: action.payload,
      };

    case BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION:
      return {
        ...state,
        redemptionSubscription: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
