import React, { FC, useEffect, useMemo } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import { verifyWebSubscriptions } from 'store/billing/general-actions';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import { t } from 'localization';
import Text from 'components/text';
import HighLightText from 'components/hight-light-text';
import ReactivateSubscriptionButton from 'components/web-payment-iframe-buttons/reactivate-subscription';
import RenewSubscriptionButton from 'components/web-payment-iframe-buttons/renew-subscription';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { WEB_SUBSCRIPTION_STATUS } from 'constants/billing';

import IMAGE_2 from './images/2-2.png';

interface Props {}

const TYPE = 'reactivation';

const WebReactivationModal: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.REACTIVATION);
  const webAllSubscriptions = useSelector((state: RootState) => state.billing?.webAllSubscriptions);
  const notRenewButtonConfig = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.reactivateCloseButton);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Web_Sub', 'Open', { type: TYPE });
    }
  }, [isVisible]);

  const status = useMemo(() => {
    const subscription = webAllSubscriptions.find(s => [WEB_SUBSCRIPTION_STATUS.CANCELED, WEB_SUBSCRIPTION_STATUS.EXPIRED].includes(s?.status));

    if (subscription) {
      return subscription?.status;
    }

    return 0;
  }, [webAllSubscriptions]);

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  const handleNotRenewPress = () => {
    Analytics.trackEvent('Web_Sub', 'NotRenew_Click', { status, type: TYPE });

    if (!notRenewButtonConfig?.link) {
      handleClosePress();
    }
  };

  const handlePress = () => {
    Analytics.trackEvent('Web_Sub', 'Button_Click', { status, type: TYPE });
  };

  const handleSkip = () => {
    Analytics.trackEvent('Web_Sub', 'Skip', { status, type: TYPE });
    dispatch(closeCurrentModal());
  };

  const handleSuccess = () => {
    Analytics.trackEvent('Web_Sub', 'Success', { status, type: TYPE });
    dispatch(verifyWebSubscriptions());
    dispatch(closeCurrentModal());
    dispatch(showModal(MODALS.REACTIVATION_THX));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <Text font="Philosopher" style={styles.title}>
          {_.startCase(t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.TITLE'))}
        </Text>

        <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
          {status === WEB_SUBSCRIPTION_STATUS.EXPIRED
            ? t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.SUB_TITLE_EXPIRED')
            : t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.SUB_TITLE_CANCELED')}
        </HighLightText>

        <Image style={styles.image} source={IMAGE_2} resizeMode="contain" />

        <ReactivateSubscriptionButton
          style={styles.button}
          buttonTitle={t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.BTN_TITLE')}
          onPress={handlePress}
          onSkip={handleSkip}
          onSuccess={handleSuccess}
          isReactivate={true}
        />

        <RenewSubscriptionButton
          buttonTitle={!notRenewButtonConfig?.title ? t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.DON_T_RENEW') : notRenewButtonConfig?.title}
          link={notRenewButtonConfig.link}
          onPress={handleNotRenewPress}
          onSkip={handleSkip}
          onSuccess={handleSuccess}
        />
      </View>
    </Modal>
  );
};

export default WebReactivationModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  image: {
    width: sw(285),
    marginTop: paddingVertical(30),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(22),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(34),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(15),
    fontSize: fs(18),
    lineHeight: fs(25),
    color: COLORS.WHITE_WITH_FIFTH_TRANSPARENCY,
    textAlign: 'center',
  },
  subTitleHightLight: {
    color: COLORS.FADED_ORANGE,
  },
  button: {
    marginTop: paddingVertical(24),
  },
});
