import { createSelector } from 'reselect';

import { RootState } from 'store';
import { WEB_ONE_TIME_PRODUCT_CODES } from 'constants/billing';

const getWebOneTimePurchases = (state: RootState) => state.billing.webOneTimePurchases;

export const getAstrologersConsultationsPurchases = createSelector([getWebOneTimePurchases], purchases => {
  return purchases.filter(purchase => purchase.product_code.indexOf(WEB_ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION) >= 0);
});

export const getPurchasedConsultations = createSelector([getWebOneTimePurchases], purchases => {
  return purchases.filter(purchase => purchase.product_code.indexOf(WEB_ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION) >= 0);
});
