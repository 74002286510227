import { WebSubscription, WebPurchase } from 'api/purchases/interfaces';
import { PERIOD_TYPES, WEB_SUBSCRIPTION_STATE, WEB_SUBSCRIPTION_STATUS } from 'constants/billing';

export const mapWebSubscription = (purchase: WebPurchase): WebSubscription => {
  const { trial_start_at, trial_end_at } = purchase;
  const purchaseState = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATE;
  let status = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATUS;

  let trial = 0;
  let trialPeriod: PERIOD_TYPES | null = null;
  let isActiveTrialPeriod = false;

  if (trial_start_at && trial_end_at) {
    const startDate = new Date(trial_start_at);
    const endDate = new Date(trial_end_at);
    const now = new Date();

    const diffTime = endDate.getTime() - startDate.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    const diffWeeks = diffDays / 7;
    const diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    const diffYears = endDate.getFullYear() - startDate.getFullYear();

    if (diffDays <= 7) {
      trial = Math.floor(diffDays);
      trialPeriod = PERIOD_TYPES.D;
    } else if (diffWeeks <= 4) {
      trial = Math.floor(diffWeeks);
      trialPeriod = PERIOD_TYPES.W;
    } else if (diffMonths <= 12) {
      trial = diffMonths;
      trialPeriod = PERIOD_TYPES.M;
    } else {
      trial = diffYears;
      trialPeriod = PERIOD_TYPES.Y;
    }

    isActiveTrialPeriod = endDate >= now;
  }

  if (
    [WEB_SUBSCRIPTION_STATE.CANCELED, WEB_SUBSCRIPTION_STATE.CANCELLED].includes(purchaseState) ||
    (purchaseState === WEB_SUBSCRIPTION_STATE.ACTIVE && purchase.canceled_at)
  ) {
    status = WEB_SUBSCRIPTION_STATUS.CANCELED;
  }

  if (purchase.expire_at) {
    const now = new Date();
    const expireDate = new Date(purchase.expire_at.replace(' ', 'T'));

    if (now > expireDate) {
      status = WEB_SUBSCRIPTION_STATUS.EXPIRED;
    }
  }

  if (purchaseState === WEB_SUBSCRIPTION_STATE.REDEMPTION) {
    status = WEB_SUBSCRIPTION_STATUS.REDEMPTION;
  }

  return {
    ...purchase,
    status,
    trial,
    trial_period: trialPeriod,
    is_active_trial_period: isActiveTrialPeriod,
  } as WebSubscription;
};
