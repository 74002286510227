import { Linking, Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { showModal, addToQueue } from 'store/modals/actions';
import { dailyRewards } from 'store/astrologers/time/actions';
import { addTriggerNotification } from 'store/astrologers/notifications/actions';
import { createOneClickPayment, getPaymentMethod, getWebSubs, getWebOneTimePurchases, getWebAllSubs } from 'api/purchases';
import { OneClickPaymentData, OneTimePaymentResponse } from 'api/purchases/interfaces';
import * as MODALS from 'constants/modals';
import { MODALS_EXCLUDE_ROUTES } from 'constants/routes';
import { WEB_SUBSCRIPTION_STATUS } from 'constants/billing';
import { SUB_CANCEL_LINK, WEB_SUB_CANCEL_LINK } from 'constants/general';
import { BENEFITS_TRIGGERS } from 'constants/sub-benefits';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components/const';
import { mapWebSubscription } from 'utils/billing';

import { setupPurchasedState } from './actions';
import { BILLING_TYPES } from './types';
import { getWebActiveSubscription } from './selectors';

const setPurchased = createAction(BILLING_TYPES.SET_PURCHASED);
const setWebPaymentMethod = createAction(BILLING_TYPES.SET_WEB_PAYMENT_METHOD);
const setWebTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
const setWebOneTimePurchases = createAction(BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES);
const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
const setRedemptionSubscription = createAction(BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION);

export const trackEvent = (event: string, params?: any) => Analytics.trackEvent('billing', event, params);

export const initSubBenefits = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { enabled, sessionNumber, showSessionsLimit, timeout } = state.remoteConfig.remoteConfigParams?.subBenefitsConfig || {};
    const { purchased } = state.billing;

    dispatch(rewardsSubBenefits());

    if (!enabled || !purchased) {
      return;
    }

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;
    const endSession = sessionNumber + showSessionsLimit;

    if (currentSession >= sessionNumber && currentSession < endSession) {
      setTimeout(() => {
        const {
          modals: { activeModal },
          navigation: { currentRoute },
        } = getState();

        if (!activeModal && !MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
          dispatch(showModal(MODALS.SUB_BENEFITS, { trigger: BENEFITS_TRIGGERS.START_APP }));
        } else {
          dispatch(addToQueue(MODALS.SUB_BENEFITS, { trigger: BENEFITS_TRIGGERS.START_APP }));
        }
      }, timeout * 1000);
    }
  };
};

export const cancelSubscription = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      billing: { webTransactions },
    } = getState();

    const webSubEmail = webTransactions?.find(sub => sub?.customer_email)?.customer_email || '';

    const idfm = webUUID ? webUUID : await DeviceProps.getIDFM();
    const link = webSubEmail ? `${WEB_SUB_CANCEL_LINK}${encodeURIComponent(webSubEmail)}&idfm=${idfm}&platform=${Platform.OS}` : SUB_CANCEL_LINK;
    Linking.openURL(link || '');
  };
};

export const rewardsSubBenefits = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { enabled, dailyMinutes } = state.remoteConfig.remoteConfigParams?.subBenefitsConfig || {};
    const { purchased } = state.billing;

    if (!enabled || !purchased) {
      return;
    }

    const secondsReward = dailyMinutes * 60;
    dispatch(dailyRewards(secondsReward));
  };
};

export const fetchPaymentMethod = () => {
  return async (dispatch: AppDispatch) => {
    const paymentMethod = await getPaymentMethod();
    return dispatch(setWebPaymentMethod(paymentMethod));
  };
};

export const payByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: OneClickPaymentData;
  onSuccess?: (res: OneTimePaymentResponse['one_time_payment']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const response = await createOneClickPayment(data);

      if (typeof onSuccess === 'function') {
        onSuccess(response.one_time_payment);
      }

      return response;
    } catch (error) {
      console.log('[ERROR PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};

export const verifyWebSubscriptions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      billing: { redemptionSubscription },
    } = getState();

    const isPaymentFailedTriggerNotificationEnabled = remoteConfigParams?.paymentFailedTriggerNotificationEnabled;
    const isLockTrialCanceledSub = remoteConfigParams?.isLockTrialCanceledSub;

    const allSubscriptions = await getWebAllSubs();
    const subscriptions = allSubscriptions.map(mapWebSubscription);
    dispatch(setWebAllSubscriptions(subscriptions));

    const redemption = subscriptions.find(s => s.status === WEB_SUBSCRIPTION_STATUS.REDEMPTION);

    if (redemption) {
      Analytics.track('Subscription_Redemption_In');

      dispatch(setRedemptionSubscription(redemption));

      if (isPaymentFailedTriggerNotificationEnabled) {
        Analytics.trackEvent('TopNotification', 'Show', {
          type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED,
        });

        dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED }));
      }
    } else if (redemptionSubscription) {
      Analytics.track('Subscription_Redemption_Out');

      dispatch(setRedemptionSubscription(null));
    }

    // IT NEEDS BECAUSE ACTUALIZED REQUEST UPDATES ACTUALLY STATES OF PURCHASES
    await dispatch(fetchWebPurchases());

    const activeCanceledTrialSubscription = subscriptions.find(i => i.is_active_trial_period && i?.canceled_at);

    const activeSubscription = getWebActiveSubscription(getState());

    if (isLockTrialCanceledSub && activeCanceledTrialSubscription && !activeSubscription) {
      dispatch(setPurchased({ purchased: false, purchasedAstrologers: false }));
    }

    return subscriptions;
  };
};

export const fetchWebPurchases = () => {
  return async (dispatch: AppDispatch) => {
    const [transactions, oneTimePurchases] = await Promise.all([getWebSubs(), getWebOneTimePurchases()]);

    dispatch(setWebTransactions(transactions));
    dispatch(setWebOneTimePurchases(oneTimePurchases));
    dispatch(fetchPaymentMethod());
    dispatch(setupPurchasedState());

    return true;
  };
};
