import { createNavigationContainerRef, StackActions, CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import * as ROUTES from 'constants/routes';
import { MAP as SUB_SCREENS_MAP } from 'constants/subscription-screens';
import Store, { AppDispatch, AppGetState, AppThunk } from 'store';
import { setInitialUrl, setWasOnContentPages } from 'store/app/actions';
import { onboardingCompleted } from 'store/onboarding/actions';
import { setCompleted as setAdvisorOnbCompleted } from 'store/astrologers/onboarding/actions';
import { showRateUsAfterFeature } from 'store/rate-us/actions';
import { FEATURE_BY_ROUTE as RATE_US_FEATURE_BY_ROUTE } from 'store/rate-us/types';
import { navigateWithMonetization, nextMonetizationStep } from 'store/unlock-content/actions';
import * as TRIGGERS from 'constants/monetization-triggers';
import { unlockBirthChart } from 'store/upsails/actions';
import { NOTIFICATION_ID as PROMO_OFFER_NOTIFICTION_ID, SUBTRUCK_PROMO_OFFER_NOTIFICATION_ID } from 'modules/promo-offers/constants';
import { setWebSubscriptionShowed } from 'store/billing/actions.web';
import { ASTROLOGERS_OPEN_PLACE, TEMPLATE_IDS } from 'screens/advisors/constants';
import { ADVISORS_CONNECTION } from 'constants/routes';
import { getAvailableTime } from 'store/astrologers/time/actions';
import { isStandalone } from 'utils/pwa';
import { handleExternalTrigger, setQuestionsCategoryAction } from 'store/astrologers/core/actions';
import { QUESTIONS_CATEGORY_ALL, QUESTIONS_CATEGORY_CARTOGRAPHY } from 'store/astrologers/core/types';

import { SET_CURRENT_ROUTE_NAME, SET_PREV_ROUTE_NAME, SET_NAVIGATED_AFTER_ONBOARDING } from './types';
import { getLinkParams, navigateToSpecificRouteWithTimeout } from './utils';

const setCurrentRouteName = createAction(SET_CURRENT_ROUTE_NAME);
const setPreviousRouteName = createAction(SET_PREV_ROUTE_NAME);
const setNavigatedAfterOnboarding = createAction(SET_NAVIGATED_AFTER_ONBOARDING);

export const navigationRef = createNavigationContainerRef();
export const routeNameRef: any = React.createRef();

export function navigate(...args) {
  // @ts-ignore
  navigationRef.current?.navigate(...args); // TODO: Fix when typing navigation
}

export function replace(...args: any) {
  navigationRef.current?.dispatch(StackActions.replace(args[0], args[1]));
}

export function reset(name: string, params = {}): typeof navigationRef | void {
  const { currentRoute } = Store.getState().navigation;
  if (currentRoute !== name) {
    if (name === ROUTES.MAIN) {
      const ref = Analytics.getAppRef();

      Analytics.track('inner_app_open', {
        ...(ref ? { ref } : {}),
        cohort_date: Analytics.cohortDate,
        platform: Platform.OS === 'web' ? (isStandalone() ? 'pwa' : 'rnw') : Platform.OS,
        cross_session_number: Store.getState()?.profile?.profileData?.userParams?.cross_session_number || 0,
      });
    }

    return navigationRef.current?.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name, params }],
      }),
    );
  }
}

export function back(): void {
  navigationRef.current?.goBack();
}

export function canGoBack(): boolean {
  return navigationRef.current?.canGoBack() ?? false;
}

export const navigationChangeCallback = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const wasOnContentPages = getState().app.wasOnContentPages;
    const previousRouteName = routeNameRef.current || ROUTES.LOADING;
    const currentRouteName = navigationRef.current?.getCurrentRoute()?.name || '';
    console.log('> currentRouteName', currentRouteName);
    if (currentRouteName === previousRouteName) {
      return false;
    }

    // track analytics screen here
    // if (previousRouteName !== currentRouteName) {
    // }

    if (!wasOnContentPages && !ROUTES.CONTENT_EXCLUDE_PAGES.includes(currentRouteName)) {
      dispatch(setWasOnContentPages());
    }

    if (RATE_US_FEATURE_BY_ROUTE[previousRouteName] && !ROUTES.RATE_US_EXCLUDE_ROUTES.includes(currentRouteName)) {
      dispatch(showRateUsAfterFeature(RATE_US_FEATURE_BY_ROUTE[previousRouteName]));
    }

    if (currentRouteName) {
      dispatch(setPreviousRouteName(previousRouteName));
      dispatch(setCurrentRouteName(currentRouteName));
    }

    if (
      ([ROUTES.ADVISORS, ROUTES.ADVISORS_QUESTIONS].includes(previousRouteName) && currentRouteName !== ROUTES.ADVISORS_CONNECTION) ||
      previousRouteName === ROUTES.ADVISORS_CHAT
    ) {
      const activeQuestionsCategory = getState().astrologers.core.activeQuestionsCategory;
      if (activeQuestionsCategory === QUESTIONS_CATEGORY_CARTOGRAPHY) {
        dispatch(setQuestionsCategoryAction(QUESTIONS_CATEGORY_ALL));
      }
    }

    routeNameRef.current = currentRouteName;
    return true;
  };
};

export function fillInProfile() {
  return function (__: AppDispatch, getState: AppGetState) {
    const profile = getState().onboarding.onboardingProfile;

    if (!profile.name) {
      return navigate(ROUTES.ONBOARDING_NAME);
    }

    if (!profile.gender) {
      return navigate(ROUTES.ONBOARDING_GENDER);
    }

    if (!profile.relationship) {
      return navigate(ROUTES.ONBOARDING_RELATIONSHIP);
    }

    if (!profile.date) {
      return navigate(ROUTES.ONBOARDING_DATE);
    }
  };
}

export function checkFillInProfile(route: any) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const { isFullProfile } = getState().app;

    if (isFullProfile) {
      navigate(route);
    } else {
      dispatch(fillInProfile());
    }
  };
}

export function onNotificationOpen(data: any, initial = false) {
  return function (
    _dispatch: AppDispatch,
    getState: AppGetState,
  ): { navigatedByNotification: boolean; isPromoInitialNotification?: boolean } | undefined {
    const {
      onboarding: { isOnboardingCompleted },
    } = getState();

    if (!data) {
      return { navigatedByNotification: false };
    }

    const id = data?.id;
    const isSubtruckPromoOfferClicked = id?.indexOf(SUBTRUCK_PROMO_OFFER_NOTIFICATION_ID) !== -1;
    const isLocalPromoOfferClicked = id?.indexOf(PROMO_OFFER_NOTIFICTION_ID) !== -1;
    const isPromoOfferClicked = isLocalPromoOfferClicked || isSubtruckPromoOfferClicked;

    if (isSubtruckPromoOfferClicked) {
      Analytics.trackEvent('subtruck_cancel_renew_notification', 'open');
    }

    Analytics.trackEvent('notification', 'open', { id, isPromoOfferClicked });

    if (isPromoOfferClicked) {
      if (initial) {
        return { navigatedByNotification: false, isPromoInitialNotification: true };
      } else {
        navigate(ROUTES.SUBSCRIPTION_PROMO_OFFER);
        return { navigatedByNotification: true };
      }
    }

    if (!isOnboardingCompleted) {
      return { navigatedByNotification: false };
    }

    navigate(ROUTES.MAIN);

    return { navigatedByNotification: true };
  };
}

export function backTermsAndPrivacy() {
  return function () {
    back();
  };
}

export function navigateAfterPurchase() {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const {
      onboarding: { isOnboardingCompleted },
      app: { isFullProfile },
    } = getState();

    if (!isOnboardingCompleted) {
      dispatch(onboardingCompleted());

      if (!isFullProfile) {
        return reset(ROUTES.ONBOARDING_NAME);
      }
    }

    dispatch(whereToNavigate());
  };
}

export function navigateToSubscriptionScreen({ trigger, triggerConfig, resetStack, onSuccess }: any = {}) {
  return function (_dispatch: AppDispatch, getState: AppGetState) {
    const state = getState();
    const { defaultSubscriptionScreen } = state.remoteConfig.remoteConfigParams;

    const screen = triggerConfig?.screen || defaultSubscriptionScreen;
    const route = SUB_SCREENS_MAP[screen] || ROUTES.SUBSCRIPTION_VERTICAL_ONE_PRODUCT;

    const params = {
      trigger,
      screen,
      config: triggerConfig,
      onSuccess,
    };

    resetStack ? reset(route, params) : navigate(route, params);
  };
}

export function navigateToInAppModal({ trigger, onSuccess }: any = {}) {
  return function (dispatch: AppDispatch) {
    if (trigger.indexOf(TRIGGERS.BIRTH_CHART) === 0) {
      dispatch(unlockBirthChart(onSuccess));
    }
  };
}

export function navigateAfterSuccessSubscription() {
  return function (dispatch: AppDispatch) {
    return dispatch(closeSubscriptionScreen(true));
  };
}

export function navigateAfterSuccessRestore() {
  return function (dispatch: AppDispatch) {
    return dispatch(closeSubscriptionScreen(true));
  };
}

export function isLastRouteSub() {
  return function (_dispatch: AppDispatch, getState: AppGetState) {
    const {
      navigation: { previousRoute },
    } = getState();

    return ROUTES.SUBSCRIPTIONS.includes(previousRoute);
  };
}

export function closeSubscriptionScreen(isAfterSuccessPurchase = false) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const {
      promoOffers: { active: isPromoOfferActive },
      unlockContent: { currentTrigger },
    } = getState();

    if (isPromoOfferActive && currentTrigger?.route) {
      return replace(currentTrigger.route, currentTrigger.params);
    }

    const isMonetizationCompleted = dispatch(nextMonetizationStep(isAfterSuccessPurchase));

    if (isMonetizationCompleted) {
      back();
    }
  };
}

export const whereToNavigate = (preventDefaultNavigation = false) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: {
          whereNavigateAfterOnboarding,
          whereNavigateAfterOnboardingPWA,
          advisorsSuggests: { selectedCategory },
        },
      },
      billing: { isWebSubscriptionShowed },
      navigation: { isNavigatedAfterOnboarding },
      auth: { webUUID },
      astrologers: {
        core: { palmReadingData, lastConnectedAstrologer, isAstrocartographyEnabled },
      },
      app: { initialUrl },
    } = getState();

    if (!webUUID && Platform.OS === 'web') {
      Analytics.trackEvent('auth', 'have_no_web_uuid');
      return reset(ROUTES.ONBOARDING_WELCOME);
    }

    if (!isWebSubscriptionShowed) {
      dispatch(
        navigateWithMonetization({
          trigger: TRIGGERS.WHERE_TO_NAVIGATE,
        }),
      );

      dispatch(setWebSubscriptionShowed());
    }

    let destination = isStandalone() ? whereNavigateAfterOnboardingPWA : whereNavigateAfterOnboarding;
    const link = Platform.OS === 'web' ? window.location.href : initialUrl;
    const [path, advisorId] = getLinkParams(link);

    if (path) {
      destination = path;
    }

    const isDestination = Object.keys(ROUTES.PATH_ROUTES_MAP).includes(destination);
    const isNavigateByParam = isDestination && !isNavigatedAfterOnboarding;
    const isNavigateByPathname = isDestination && !!path;

    const isPromocodeActivated = await dispatch(handleExternalTrigger());

    if (isNavigateByPathname || isNavigateByParam) {
      const route = ROUTES.PATH_ROUTES_MAP[destination];
      const params: { [key: string]: string | number } = {};

      if (destination === ROUTES.PATH_NAMES.chat) {
        dispatch(setAdvisorOnbCompleted());

        if (selectedCategory) {
          dispatch(setQuestionsCategoryAction(selectedCategory));
        }

        if (!isPromocodeActivated) {
          await dispatch(getAvailableTime());
        }

        if (palmReadingData) {
          params.templateId = TEMPLATE_IDS.PALM_READING;
        }

        if (isAstrocartographyEnabled) {
          params.templateId = TEMPLATE_IDS.ASTROCARTOGRAPHY;
        }

        params.id = advisorId ? advisorId : lastConnectedAstrologer?.astrologer_id;
        params.place = isNavigateByPathname ? ASTROLOGERS_OPEN_PLACE.LINK : isNavigateByParam ? ASTROLOGERS_OPEN_PLACE.WHERE_TO_NAVIGATE : 'other';
      }

      dispatch(setInitialUrl(null));
      dispatch(setNavigatedAfterOnboarding(true));
      return navigateToSpecificRouteWithTimeout(route, params);
    }

    if (preventDefaultNavigation) {
      return;
    }

    return reset(ROUTES.MAIN);
  };
};

export const handleNotificationOpen = payload => {
  return (dispatch: AppDispatch) => {
    // console.log('> handleNotificationOpen');
    // if (payload?.data) {
    //   payload.data.astrologer_id = 4;
    // }

    // alert('handle notification Open: \n' + JSON.stringify(payload, null, 2));

    const advisorId = payload?.data?.astrologer_id;
    if (advisorId) {
      dispatch(navigateToAdvisorByPushNotification(advisorId, payload?.notification.body));
    }
  };
};

export const handleNotificationPostMessage = payload => {
  return (dispatch: AppDispatch) => {
    const advisorId = payload?.astrologer_id;
    if (advisorId) {
      dispatch(navigateToAdvisorByPushNotification(advisorId, payload?.message));
    }
  };
};

export const navigateToAdvisorByPushNotification = (advisorId: string, pushNotification: string) => {
  return (dispatch: AppDispatch) => {
    if (advisorId) {
      Analytics.trackEvent('notification', 'go_advisor_chat', { advisorId });
      dispatch(setAdvisorOnbCompleted());
      navigate(ADVISORS_CONNECTION, { id: advisorId, place: ASTROLOGERS_OPEN_PLACE.PUSH_NOTIFICATION, pushNotification });
    }
  };
};
