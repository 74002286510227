import { Currency } from 'constants/billing';

export enum PACK_SCREENS_IDS {
  PACKS = 'PACKS',
  WELCOME_OFFER = 'WELCOME_OFFER',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
  SPECIAL_OFFER_2 = 'SPECIAL_OFFER_2',
}

export enum PACK_TRIGGER_IDS {
  PACKS = 'asb_packs',
  WELCOME_OFFER = 'asb_packs_offer_welcome',
  OFFER_1 = 'asb_packs_offer1',
  OFFER_2 = 'asb_packs_offer2',
}

export interface AstrologerCreditsConfig {
  enabled: boolean;
  rate: number;
}

export interface AstrologerPacksFlows {
  noPurchased: PACK_SCREENS_IDS[];
  purchased: PACK_SCREENS_IDS[];
}

export type AstrologerPackRoutes = {
  [key in PACK_SCREENS_IDS]: string;
};

export enum ASTROLOGERS_TRIGGERS_TYPES {
  BALANCE = 'balance',
  CHAT_BTN = 'chat_btn',
  CHAT_TIME_UP = 'chat_time_up',
  CHAT_NOTIFICATION = 'chat_notification',
  END_CHAT = 'end_chat',
  ADVISORS_ONBOARDING = 'advisors_onboarding',
  BLUR_MESSAGE = 'blur_message',
  SUGGESTS = 'suggests',
  SUGGESTS_POPUP = 'suggests_popup',
  AUTO_REFILL = 'auto_refill',
  CONNECTION_OPEN = 'connection_open',
  TIP = 'tip',
}

export type AstrologersMonetizationTriggers = Exclude<
  ASTROLOGERS_TRIGGERS_TYPES,
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION
  | ASTROLOGERS_TRIGGERS_TYPES.ADVISORS_ONBOARDING
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP
>;

export type AstrologersMonetizationConfig = {
  [key in AstrologersMonetizationTriggers]?: boolean;
};

export interface CurrencyConfig {
  notActivePrice: string;
  activePrice: string;
  price: number;
}

export enum RightTextColors {
  GREEN = 'green',
}

export type RightTextColor = RightTextColors | string | null;

export interface Pack {
  title: string;
  minutesAmount: number;
  notActivePrice: string;
  activePrice: string;
  freeMinsAmount: number;
  saveAmount: string;
  price: number;
  badgeText: string;
  rightText?: string;
  rightTextColor?: RightTextColor;
  rightSubText?: string;
  currency: Currency;
  USD?: CurrencyConfig;
  EUR?: CurrencyConfig;
  CAD?: CurrencyConfig;
  AUD?: CurrencyConfig;
}

export interface Product extends Pack {
  id: string;
  pricePerMin: string;
  credits: number;
  creditsRate?: number;
  pricePerCredit: string;
}

export enum PackType {
  ONE,
  TWO,
  THREE,
  FOUR,
}

export enum ViewType {
  FREE_MINS = 'freeMins',
  SAVE = 'save',
}

interface AstrologerConfigBase {
  title: string;
  subtitle: string;
  btnText: string;
  footnoteText: string;
}

export interface AstrologerPackSpecialOffer extends AstrologerConfigBase {
  pack: Pack;
}

export interface AstrologerPackWelcomeOffer extends AstrologerConfigBase {
  pack: Pack;
  percentsOff: number;
}

export interface AstrologerConsultationPacks extends AstrologerConfigBase {
  packType: PackType;
  withCloseIcon: boolean;
  defaultProductId: number;
  viewType: ViewType;
  packs: Pack[];
}
