import { WEB_PAYMENT_SERVICES, WEB_SUBSCRIPTION_STATUS, WEB_SUBSCRIPTION_STATE, WebProducts, Currency, PERIOD_TYPES } from 'constants/billing';
import { ApplePayDetailsPaymentData } from 'modules/payments/apple-pay/interfaces';

export interface WebPurchase {
  resource_type: string;
  id: number;
  external_id: string;
  payment_service: WEB_PAYMENT_SERVICES;
  active: number;
  currency: Currency;
  product: string;
  trial_start_at: string | null;
  trial_end_at: string | null;
  expire_at: string;
  canceled_at: string | null;
  created_at: string;
  updated_at: string;
  customer_email: string;
  email: string;
  sig: string;
  state: WEB_SUBSCRIPTION_STATE;
}

export interface WebSubscription extends WebPurchase {
  status: WEB_SUBSCRIPTION_STATUS;
  trial: number;
  trial_period: PERIOD_TYPES | null;
  is_active_trial_period: boolean;
}

export type WebPurchases = WebPurchase[];
export type WebSubscriptions = WebSubscription[];

export interface WebOneTimePurchase {
  id: number;
  payment_service: WEB_PAYMENT_SERVICES;
  project: string;
  product_code: WebProducts;
  order_id: string;
  email: string;
  amount: number;
  currency: Currency;
  user_id: number;
  customer_id: string;
  subscription_id: number;
  description: string;
  status: number;
  created_at: string;
  updated_at: string;
  trigger: string | null;
  metadata: string | null;
}

export type WebOneTimePurchases = WebOneTimePurchase[];

export interface SolidMetadata {
  adjust_id?: string;
  idfm?: string;
  idfv?: string;
  idfa?: string;
  firebase_instance_id?: string;
  web_uuid?: string;
}

interface PaymentData {
  amount: number;
  currency: Currency;
  productCode: string;
  metadata: any;
  description: string;
  trigger?: string;
  solidMetadata?: SolidMetadata;
}

export interface OneClickPaymentData extends PaymentData {}

export interface ApplePayPaymentData extends PaymentData {
  data: string;
  header: ApplePayDetailsPaymentData['header'];
  signature: ApplePayDetailsPaymentData['signature'];
  version: string;
  email: string;
}

export interface GooglePayPaymentData extends PaymentData {
  signature: string;
  signedMessage: string;
  protocolVersion: string;
  merchant: string;
  platform: 'APP';
  amount: number;
  currency: Currency;
  email: string;
}

export enum PaymentMethods {
  CARD = 'card',
  PAYPAL = 'paypal',
  APPLE_PAY = 'applepay',
  GOOGLE_PAY = 'googlepay',
}

export interface OneTimePaymentResponse {
  verify_url?: string;
  one_time_payment: {
    payment_service: WEB_PAYMENT_SERVICES;
    project: string;
    order_id: string;
    product_code: string;
    email: string;
    user_id: string;
    customer_id: string;
    currency: string;
    amount: string;
    description: string;
    status: string;
    trigger: string;
    metadata: string;
    created_at: string;
    updated_at: string;
    id: string;
    session_number: string;
    retention_day: string;
  };
}
